import React, { useState } from "react";
import styled from "@emotion/styled";
import {
  Box,
  Popover as MuiPopover,
  Typography,
  Button as MuiButton,
  CircularProgress,
} from "@mui/material";

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    padding: 16px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const ContentBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const FlexBox = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
`;

const ButtonBox = styled(Box)`
  display: flex;
  gap: "16px";
  width: 100%;
  justify-content: center;
  margin-top: 16px;
`;

const Button = styled(MuiButton)`
  margin: 0 8px;
`;

const PopoverDelete = ({
  isDeletePopupOpen,
  handleCloseDeletePopup,
  rebootRef,
  handleDelete,
}) => {
  return (
    <Popover
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      anchorEl={rebootRef}
      onClose={handleCloseDeletePopup}
      open={isDeletePopupOpen}
    >
      <ContentBox>
        <FlexBox>
          <Typography variant="body2" sx={{ marginTop: "8px" }}>
            Confermi di voler eliminare la riga?
          </Typography>
        </FlexBox>
        <ButtonBox>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleCloseDeletePopup}
          >
            No
          </Button>
          <Button variant="contained" color="primary" onClick={handleDelete}>
            Si
          </Button>
        </ButtonBox>
      </ContentBox>
    </Popover>
  );
};

export default PopoverDelete;
