import React, { useState, useEffect, useReducer } from "react";
import {
  Box,
  Button as MuiButton,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Divider as MuiDivider,
  Popover as MuiPopover,
  Tab,
  Tabs,
  TextField,
  Typography,
  Tooltip,
  Select,
  InputLabel,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import Opzioni from "./Opzioni";
import { useNavigate, useParams } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers";
import Tabella from "./Tabella";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../../redux/slices/snackbar";
import { Save, SaveAs } from "@mui/icons-material";
import "dayjs/locale/it";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import DuplicaModale from "./DuplicaModale";

dayjs.extend(customParseFormat);
dayjs.extend(utc);

const Divider = styled(MuiDivider)(spacing);

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    padding: 16px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const ContentBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const FlexBox = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
`;

const ButtonBox = styled(Box)`
  display: flex;
  gap: "16px";
  width: 100%;
  justify-content: center;
  margin-top: 16px;
`;

const Button = styled(MuiButton)`
  margin: 0 8px;
`;

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};
const ITEM_HEIGHT = 62;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const initialState = {
  errorNumeroPolizza: false,
  errorNome: false,
  errorCodice: false,
  errorScadenza: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_ERRORS":
      return { ...state, ...action.payload };
    case "CLEAR_ERRORS":
      return initialState;
    default:
      return state;
  }
};

function LibriMatricolaDetails() {
  const [valueTabs, setValueTabs] = useState(0);
  const [loadingPage, setLoadingPage] = useState(true);
  const [dataArray, setDataArray] = useState([]);
  const navigate = useNavigate();

  const [nPolizza, setNPolizza] = useState("");
  const [nome, setNome] = useState("");
  const [codice, setCodice] = useState("");
  const [idUid, setIdUid] = useState("");

  const [tipo, setTipo] = useState("");
  const [tipoArray, setTipoArray] = useState([]);
  const [compagnia, setCompagnia] = useState("");
  const [compagniaArray, setCompagniaArray] = useState([]);
  const [tipoVeicolo, setTipoVeicolo] = useState("");
  const [tipoVeicoloArray, setTipoVeicoloArray] = useState([]);

  const [fornitore, setFornitore] = useState("");
  const [fornitoreArray, setFornitoreArray] = useState([]);

  const [datagrid, setDatagrid] = useState([]);
  const [opzioni, setOpzioni] = useState();

  const [scadenza, setScadenza] = useState(null);

  const [openPopover, setOpenPopover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState("");

  const { idUID } = useParams();

  const [state, dispatchReducer] = useReducer(reducer, initialState);

  const [openModaleDuplica, setOpenModaleDuplica] = useState(false);

  const handleClickModale = () => {
    setOpenModaleDuplica(true);
  };

  const formData = (data) => {
    setNPolizza(data.numeroPolizza);
    setNome(data.nome);
    setCodice(data.codice);
    setTipoArray(data.tipo[0]);
    setTipo(data.tipo[0].value);
    setCompagniaArray(data.compagnia[0]);
    setCompagnia(data.compagnia[0].value);
    setTipoVeicolo(data.tipoVeicolo[0].value);
    setTipoVeicoloArray(data.tipoVeicolo[0]);
    setFornitoreArray(data.fornitore[0]);
    setFornitore(data.fornitore[0].value);
    setScadenza(
      data.scadenza === ""
        ? null
        : dayjs(data.scadenza, "DD/MM/YYYY").utc().toISOString()
    );
    setDatagrid(data.Tariffe[0].dataset);
    setIdUid(data.idUID);
    setOpzioni({
      scontoMaggiorazione: data.scontoMaggiorazione[0].value,
      scontoMaggiorazioneTipo: data.scontoMaggiorazioneTipo[0].value,
      scontoMaggiorazioneValore: data.scontoMaggiorazioneValore,
      franchigia: data.franchigia,
      franchigiaImporto: data.franchigiaImporto,
      franchigiaValore: data.franchigiaValore,
      franchigiaScoperto: data.franchigiaScoperto[0].value,
      franchigiaNota: data.franchigiaNota,
      contraente: data.contraente,
      contraenteNota: data.contraenteNota,
      ratinoRinnovo: data.ratinoRinnovo,
      ratinoRinnovoGiorni: data.ratinoRinnovoGiorni,
      tipoGuida: data.tipoGuida[0].value,
      caricoScarico: data.caricoScarico[0].value,
      costoIngresso: data.costoIngresso || "0",
      massimale: data.massimale,
      rinunciaRivalsa: data.rinunciaRivalsa,
      autorizzazioneIncasso: data.autorizzazioneIncasso,
    });
  };

  const handleTabs = (event, newValue) => {
    setValueTabs(newValue);
  };

  async function lmGetDetails(idUID) {
    const token = sessionStorage.getItem("token");
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token,
          data: {
            idUID: idUID === undefined ? "" : idUID,
          },
        }),
      };

      const response = await fetch(
        "https://devops.mobilityexpress.it/api/lmGetDetails",
        requestOptions
      );

      const responseData = await response.json();
      if (responseData.status === -1) {
        navigate("/auth/sign-in");
      }
      if (responseData.status === 0) {
        navigate("/private");
      }
      const data = responseData.data[0];
      setDataArray(data);
      formData(data);
      setLoadingPage(false);
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  useEffect(() => {
    lmGetDetails(idUID);
  }, []);

  const dispatch = useDispatch();
  const handleSnackbar = (message, severity) => {
    dispatch(showSnackbar({ message: message, severity: severity }));
  };

  const handleSave = async () => {
    const json = {
      numeroPolizza: nPolizza,
      nome: nome,
      codice: codice,
      tipo: tipo,
      compagnia: compagnia,
      tipoVeicolo: tipoVeicolo,
      fornitore: fornitore,
      scadenza: formatDate(scadenza),
      idUID: idUid,
    };
    const errors = {
      errorNumeroPolizza: nPolizza === "" ? true : false,
      errorNome: nome === "" ? true : false,
      errorCodice: codice === "" ? true : false,
      errorScadenza: scadenza === null ? true : false,
    };
    if (
      errors.errorNumeroPolizza ||
      errors.errorNome ||
      errors.errorCodice ||
      (errors.errorScadenza && datagrid.length === 0)
    ) {
      dispatchReducer({ type: "SET_ERRORS", payload: errors });
      handleSnackbar(
        "Compilare tutti i campi obbligatori e inserire almeno una tariffa",
        "error"
      );
      setOpenPopover(false);
      return;
    }
    if (datagrid.length === 0) {
      handleSnackbar("Inserire almeno una tariffa", "error");
      setOpenPopover(false);
      return;
    }

    if (
      errors.errorNumeroPolizza ||
      errors.errorNome ||
      errors.errorCodice ||
      errors.errorScadenza
    ) {
      dispatchReducer({ type: "SET_ERRORS", payload: errors });
      handleSnackbar("Compilare tutti i campi obbligatori", "error");
      setOpenPopover(false);
      return;
    } else {
      dispatchReducer({ type: "CLEAR_ERRORS" });
    }

    const sendJson = {
      libroMatricola: json,
      tariffe: datagrid,
      opzioni: opzioni,
    };

    console.log(sendJson);

    const token = sessionStorage.getItem("token");
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token,
          data: sendJson,
        }),
      };

      const response = await fetch(
        "https://devops.mobilityexpress.it/api/lmNewUpdate",
        requestOptions
      );

      const responseData = await response.json();
      if (responseData.status === -1) {
        navigate("/auth/sign-in");
      }
      if (responseData.status === 1) {
        handleSnackbar(responseData.message, "success");
      } else {
        handleSnackbar(responseData.message, "error");
        setOpenPopover(false);
        return;
      }
      if (responseData.data.idUID) {
        navigate(`/pages/libriMatricolaDetails/${responseData.data.idUID}`);
        setLoadingPage(true);
        await lmGetDetails(responseData.data.idUID);
      }
    } catch (error) {
      console.log(error);
      return error;
    }
    setOpenPopover(false);
    return;
  };

  const handleClickPopover = (event, content) => {
    setAnchorEl(event.currentTarget);
    setPopoverContent(content);
    setOpenPopover(true);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setOpenPopover(false);
  };

  const formatDate = (date) => {
    return date ? dayjs(date).format("DD/MM/YYYY") : "";
  };

  return (
    <React.Fragment>
      {loadingPage ? (
        <Box
          sx={{
            width: "calc(100vw - 40px)",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <React.Fragment>
          <Helmet title={nome} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h3" display="inline">
              {nome === "" ? "Nuovo Libro Matricola" : nome}
            </Typography>
            <DuplicaModale
              open={openModaleDuplica}
              setOpen={setOpenModaleDuplica}
            />
            <Box display="flex" flexDirection="row">
              {idUid !== "0" && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "16px",
                  }}
                >
                  <Tooltip title="Duplica">
                    <Button variant="contained" onClick={handleClickModale}>
                      <SaveAs />
                    </Button>
                  </Tooltip>
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: "16px",
                }}
              >
                <Tooltip title="Salva">
                  <Button
                    variant="contained"
                    onClick={(event) =>
                      handleClickPopover(event, "Confermi di voler salvare?")
                    }
                  >
                    <Save />
                  </Button>
                </Tooltip>
              </Box>
              {/* Popover */}
              <Popover
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
              >
                <ContentBox>
                  <FlexBox>
                    <Typography variant="body2" sx={{ marginTop: "8px" }}>
                      {popoverContent}
                    </Typography>
                  </FlexBox>
                  <ButtonBox>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => setOpenPopover(false)}
                    >
                      No
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSave}
                    >
                      Si
                    </Button>
                  </ButtonBox>
                </ContentBox>
              </Popover>
            </Box>
          </Box>
          <Divider my={4} />
          <Card sx={{ marginBottom: "16px", paddingTop: "8px" }}>
            <CardContent>
              <Grid
                container
                spacing={2}
                wrap="wrap"
                sx={{ maxWidth: { xs: "calc(100vw - 60px)" } }}
              >
                <Grid item sm={3} xs={12}>
                  <TextField
                    size="small"
                    margin="none"
                    label="N° Polizza"
                    required
                    fullWidth
                    value={nPolizza}
                    id="nPolizza"
                    onChange={(event) => {
                      setNPolizza(event.target.value);
                      dispatchReducer({
                        type: "SET_ERRORS",
                        payload: { errorNumeroPolizza: false },
                      });
                    }}
                    error={state.errorNumeroPolizza}
                  />
                </Grid>
                <Grid item sm={3} xs={12}>
                  <TextField
                    size="small"
                    margin="none"
                    label="Nome"
                    required
                    fullWidth
                    value={nome}
                    id="nome"
                    onChange={(event) => {
                      setNome(event.target.value);
                      dispatchReducer({
                        type: "SET_ERRORS",
                        payload: { errorNome: false },
                      });
                    }}
                    error={state.errorNome}
                  />
                </Grid>
                <Grid item sm={3} xs={12}>
                  <TextField
                    size="small"
                    margin="none"
                    label="Codice"
                    required
                    fullWidth
                    value={codice}
                    id="codice"
                    onChange={(event) => {
                      setCodice(event.target.value);
                      dispatchReducer({
                        type: "SET_ERRORS",
                        payload: { errorCodice: false },
                      });
                    }}
                    error={state.errorCodice}
                  />
                </Grid>
                <Grid item sm={3} xs={12}>
                  <TextField
                    id="tipo"
                    fullWidth
                    select
                    label="Tipo"
                    defaultValue={tipoArray.value}
                    value={tipo}
                  >
                    {tipoArray.dataset.map((el) => (
                      <MenuItem
                        key={el.label}
                        value={el.value}
                        onClick={() => setTipo(el.value)}
                      >
                        {el.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item sm={3} xs={12}>
                  <FormControl size="small" fullWidth margin="none">
                    <InputLabel id="compagnia-label">Compagnia</InputLabel>
                    <Select
                      labelId="compagnia-label"
                      id="compagnia"
                      label="Compagnia"
                      defaultValue={compagniaArray.value}
                      value={compagnia}
                      MenuProps={MenuProps}
                    >
                      {compagniaArray.dataset.map((el) => (
                        <MenuItem
                          key={el.label}
                          value={el.value}
                          onClick={() => setCompagnia(el.value)}
                        >
                          {el.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={3} xs={12}>
                  <TextField
                    id="tipoVeicolo"
                    fullWidth
                    select
                    label="Tipo Veicolo"
                    defaultValue={tipoVeicoloArray.value}
                    value={tipoVeicolo}
                  >
                    {tipoVeicoloArray.dataset.map((el) => (
                      <MenuItem
                        key={el.label}
                        value={el.value}
                        onClick={() => setTipoVeicolo(el.value)}
                      >
                        {el.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item sm={3} xs={12}>
                  <FormControl size="small" fullWidth margin="none">
                    <InputLabel id="fornitore-label">Compagnia</InputLabel>
                    <Select
                      labelId="fornitore-label"
                      id="fornitore"
                      label="Fornitore"
                      defaultValue={fornitoreArray.value}
                      value={fornitore}
                      MenuProps={MenuProps}
                    >
                      {fornitoreArray.dataset.map((el) =>
                        el.value === 0 ? (
                          <MenuItem
                            key={el.label}
                            onClick={() => setFornitore(el.value)}
                            sx={{ height: 30 }}
                            value={el.value}
                          >
                            {el.label}
                          </MenuItem>
                        ) : (
                          <MenuItem
                            key={el.label}
                            value={el.value}
                            onClick={() => setFornitore(el.value)}
                          >
                            {el.label}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={3} xs={12}>
                  <FormControl fullWidth size="small" margin="none">
                    <DatePicker
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          required
                          error={state.errorScadenza}
                        />
                      )}
                      label="Scadenza"
                      inputFormat="dd/MM/yyyy"
                      value={scadenza}
                      onChange={(newValue) => {
                        setScadenza(newValue);
                        dispatchReducer({
                          type: "SET_ERRORS",
                          payload: { errorScadenza: false },
                        });
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Tabs
            value={valueTabs}
            onChange={handleTabs}
            sx={{ marginTop: "4px" }}
          >
            <Tab label="Tariffe" />
            <Tab label="Opzioni" />
          </Tabs>
          <Card sx={{ marginTop: "16px" }}>
            <CardContent>
              <CustomTabPanel value={valueTabs} index={0}>
                <Tabella
                  data={dataArray}
                  tipoVeicolo={tipoVeicolo}
                  setDatagrid={setDatagrid}
                />
              </CustomTabPanel>
              <CustomTabPanel value={valueTabs} index={1}>
                <Opzioni data={dataArray} setOpzioni={setOpzioni} />
              </CustomTabPanel>
            </CardContent>
          </Card>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
export default LibriMatricolaDetails;

//Mettere come obbligatorio tutte le text field che stanno sopra
//Tasto salva in alto sopra con popover di conferma e poi un tasto duplica affianco che richiama un'altra api
