import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/system";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../../redux/slices/snackbar";

const DuplicaModale = ({ open, setOpen }) => {
  const isUnder600px = useMediaQuery("(max-width:768px)");
  const theme = useTheme();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    nPolizza: "",
    nome: "",
    codice: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCrea = () => {
    const newErrors = {
      nPolizza: formData.nPolizza.trim() === "",
      nome: formData.nome.trim() === "",
      codice: formData.codice.trim() === "",
    };

    setErrors(newErrors);

    // Check if all fields are valid
    const isValid = !Object.values(newErrors).includes(true);
    if (isValid) {
      dispatch(
        showSnackbar({
          message: "Duplicazione riuscita",
          severity: "success",
        })
      );
      return;
    } else {
      dispatch(
        showSnackbar({
          message: "I valori non possono essere vuoti.",
          severity: "error",
        })
      );
      return;
    }
    //Validazione
    /* if (!nameError) {
        servicesNewUpdate({
          scope: scopeCodice,
          nome: servizio,
          idStato: statoCodice,
        });
      } else {
        dispatch(
          showSnackbar({
            message: "Nome servizio non valido! Usa solo lettere e numeri.",
            severity: "error",
          })
        );
      } */
  };

  async function servicesNewUpdate(data) {
    const token = sessionStorage.getItem("token");
  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "50vw", // Set your width here
              backgroundColor:
                theme.palette.mode === "dark" ? "#1B2635" : "#F7F9FC",
            },
          },
        }}
      >
        <DialogTitle>Duplica Libro Matricola</DialogTitle>
        <DialogContent>
          <form
            style={
              isUnder600px
                ? {
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }
                : { display: "flex", alignItems: "center", gap: "16px" }
            }
          >
            <FormControl
              sx={{ mt: 2 }}
              m={2}
              style={{ minWidth: 120 }}
              size="small"
            >
              <TextField
                size="small"
                margin="none"
                label="N° Polizza"
                required
                fullWidth
                id="nPolizza"
                name="nPolizza"
                value={formData.nPolizza}
                error={errors.nPolizza}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl
              m={2}
              sx={{ mt: 2 }}
              style={{ minWidth: 120 }}
              size="small"
            >
              <TextField
                size="small"
                margin="none"
                label="Nome"
                required
                fullWidth
                id="nome"
                name="nome"
                value={formData.nome}
                error={errors.nome}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl
              m={2}
              sx={{ mt: 2 }}
              style={{ minWidth: 120 }}
              size="small"
            >
              <TextField
                size="small"
                margin="none"
                label="Codice"
                required
                fullWidth
                id="codice"
                name="codice"
                value={formData.codice}
                error={errors.codice}
                onChange={handleChange}
              />
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            sx={{ marginRight: "8px" }}
          >
            Chiudi
          </Button>
          <Button
            onClick={handleCrea}
            variant="contained"
            sx={{ marginRight: "8px" }}
          >
            Duplica
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default DuplicaModale;
