import styled from "@emotion/styled";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import CircleIcon from "@mui/icons-material/Circle";

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const Score = ({ report }) => {
  const is768px = useMediaQuery("(min-width:768px)");
  const is1024px = useMediaQuery("(min-width:1024px)");
  const currentScoreRisk =
    report.creditScore.currentCreditRating.providerValue.value;
  const previusScoreRisk =
    report.creditScore.previousCreditRating.providerValue.value;
  const currentCreditRating =
    report.creditScore.currentCreditRating.creditLimit.value;
  const previousCreditRating =
    report.creditScore.previousCreditRating.creditLimit.value;

  const localIndicators = report.localIndicators;

  const borderBottomColorScoreRisk = (score) => {
    if (score <= 20 || score === null || isNaN(score)) {
      return "4px solid #ee2e24"; //Risk High
    } else if (score >= 21 && score <= 29) {
      return "4px solid #f7941d"; //Risk Medium High
    } else if (score >= 30 && score <= 50) {
      return "4px solid #f3c534"; //Risk Medium
    } else if (score >= 51 && score <= 70) {
      return "4px solid #6dc797"; //Risk Medium Low
    } else if (score >= 71) {
      return "4px solid #409a6a"; //Risk Low
    }
  };

  const borderBottomColorCreditLimitValue = (credit) => {
    if (credit <= 0) {
      return "4px solid #ee2e24";
    } else if (credit >= 0 && credit <= 10000) {
      return "4px solid #6dc797";
    } else return "4px solid #409a6a";
  };

  const dettagliScoreArray = [
    {
      circle: <CircleIcon sx={{ color: "#409a6a", width: "12px" }} />,
      score: "71 a 100",
      descScore: "Richio molto basso",
      ScoreInter: "A",
      descScoreInter: "Affidabilità Molto Alta",
    },
    {
      circle: <CircleIcon sx={{ color: "#6dc797", width: "12px" }} />,
      score: "51 a 70",
      descScore: "Richio basso",
      ScoreInter: "B",
      descScoreInter: "Affidabilità Alta",
    },
    {
      circle: <CircleIcon sx={{ color: "#f3c534", width: "12px" }} />,
      score: "30 a 50",
      descScore: "Richio moderato",
      ScoreInter: "C",
      descScoreInter: "Affidabilità",
    },
    {
      circle: <CircleIcon sx={{ color: "#f7941d", width: "12px" }} />,
      score: "21 a 29",
      descScore: "Richio alto",
      ScoreInter: "D",
      descScoreInter: "Attenzione - Affidabilità Bassa",
    },
    {
      circle: <CircleIcon sx={{ color: "#ee2e24", width: "12px" }} />,
      score: "1 a 20",
      descScore: "Richio molto alto",
      ScoreInter: "D",
      descScoreInter: "Attenzione - Affidabilità Bassa",
    },
    {
      circle: <CircleIcon sx={{ color: "#ee2e24", width: "12px" }} />,
      score: "Non disponibile",
      descScore: "Non disponibile",
      ScoreInter: "E",
      descScoreInter: "Non Disponibile",
    },
  ];

  const esgRisk = () => {
    const code = report.localIndicators[0].value.code;

    switch (code) {
      case "H":
        return {
          color: "#f7941d",
          limit: 6,
        };
      case "MH":
        return {
          color: "#e58b4c",
          limit: 5,
        };
      case "M":
        return {
          color: "#ecbd46",
          limit: 4,
        };
      case "ML":
        return {
          color: "#f5e375",
          limit: 3,
        };
      case "L":
        return {
          color: "#6dc797",
          limit: 3,
        };

      default:
        break;
    }
  };

  return (
    <Grid container spacing={4} mt={0.5}>
      <Grid item md={6} sm={12} xs={12} pt={0}>
        <Card sx={{ height: "100%" }}>
          <CardContent>
            <Box marginBottom={4}>
              <Typography fontSize="1rem">Score</Typography>
            </Box>
            <Grid container spacing={6}>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Card
                  sx={{
                    borderBottom: borderBottomColorScoreRisk(currentScoreRisk),
                    height: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <CardContent
                    sx={{
                      mx: 4,
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      alignItems: "center",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="h6"
                      mb={6}
                      textAlign="center"
                      fontSize="0.8rem"
                      fontWeight={100}
                    >
                      Attuale
                    </Typography>
                    <Typography
                      variant="h3"
                      mb={2}
                      textAlign="center"
                      fontSize="1.5rem"
                    >
                      {currentScoreRisk}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Card
                  sx={{
                    borderBottom: borderBottomColorScoreRisk(previusScoreRisk),
                    height: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <CardContent
                    sx={{
                      mx: 4,
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      alignItems: "center",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="h6"
                      mb={6}
                      textAlign="center"
                      fontSize="0.8rem"
                      fontWeight={100}
                    >
                      Precedente
                    </Typography>
                    <Typography
                      variant="h3"
                      mb={2}
                      textAlign="center"
                      fontSize="1.5rem"
                    >
                      {previusScoreRisk}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Card
                  sx={{
                    borderBottom: "4px solid #cbcbcb",
                    height: "100%",
                  }}
                >
                  <CardContent
                    sx={{
                      mx: 4,
                      display: "flex",
                      justifyContent: "space-between",
                      alignContent: "end",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="h6"
                      mb={6}
                      textAlign="center"
                      fontSize="0.8rem"
                      fontWeight={100}
                    >
                      Ultimo Cambiamento
                    </Typography>
                    <Typography
                      variant="h3"
                      mb={2}
                      textAlign="center"
                      display="flex"
                      justifyContent="center"
                      fontSize={is1024px ? "1rem" : "1.5rem"}
                    >
                      {new Date(
                        report.creditScore.latestRatingChangeDate
                      ).toLocaleDateString()}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={6} sm={12} xs={12} pt={0}>
        <Card sx={{ height: "100%" }}>
          <CardContent>
            <Box marginBottom={4}>
              <Typography fontSize="1rem">Credito</Typography>
            </Box>
            <Grid container spacing={6}>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Card
                  sx={{
                    borderBottom:
                      borderBottomColorCreditLimitValue(currentCreditRating),
                    height: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <CardContent
                    sx={{
                      mx: 4,
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      alignItems: "center",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="h6"
                      mb={6}
                      textAlign="center"
                      fontSize="0.8rem"
                      fontWeight={100}
                    >
                      Attuale
                    </Typography>
                    <Typography
                      variant="h3"
                      mb={2}
                      textAlign="center"
                      fontSize={
                        is768px && is1024px && currentCreditRating >= 1000
                          ? "1rem"
                          : "1.5rem"
                      }
                    >
                      {new Intl.NumberFormat("it-IT", {
                        style: "currency",
                        currency: "EUR",
                      }).format(currentCreditRating)}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Card
                  sx={{
                    borderBottom:
                      borderBottomColorCreditLimitValue(previousCreditRating),
                    height: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <CardContent
                    sx={{
                      mx: 4,
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      alignItems: "center",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="h6"
                      mb={6}
                      textAlign="center"
                      fontSize="0.8rem"
                      fontWeight={100}
                    >
                      Precedente
                    </Typography>
                    <Typography
                      variant="h3"
                      mb={2}
                      textAlign="center"
                      fontSize={
                        is768px && is1024px && previousCreditRating >= 1000
                          ? "1rem"
                          : "1.5rem"
                      }
                    >
                      {previousCreditRating
                        ? new Intl.NumberFormat("it-IT", {
                            style: "currency",
                            currency: "EUR",
                          }).format(previousCreditRating)
                        : "0,00 €"}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Card
                  sx={{
                    borderBottom: "4px solid #cbcbcb",
                    height: "100%",
                  }}
                >
                  <CardContent
                    sx={{
                      mx: 4,
                      display: "flex",
                      justifyContent: "space-between",
                      alignContent: "end",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="h6"
                      mb={6}
                      textAlign="center"
                      fontSize="0.8rem"
                      fontWeight={100}
                    >
                      Ultimo Cambiamento
                    </Typography>
                    <Typography
                      variant="h3"
                      mb={2}
                      textAlign="center"
                      display="flex"
                      justifyContent="center"
                      fontSize={is1024px ? "1rem" : "1.5rem"}
                    >
                      {new Date(
                        report.creditScore.latestRatingChangeDate
                      ).toLocaleDateString()}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <Card sx={{ height: "100%" }}>
          <CardContent>
            <Box marginBottom={4}>
              <Typography fontSize="1rem">Dettagli Score</Typography>
            </Box>
            <TableWrapper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Score</TableCell>
                    <TableCell>Descrizione Score</TableCell>
                    <TableCell>Score Interanzionale</TableCell>
                    <TableCell>Descrizione Score Interanzionale</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dettagliScoreArray.map((row) => (
                    <TableRow key={row.score}>
                      <TableCell>
                        <Box
                          height="100%"
                          display="flex"
                          alignItems="center"
                          gap="4px"
                        >
                          {row.circle}
                          {row.score}
                        </Box>
                      </TableCell>
                      <TableCell>{row.descScore}</TableCell>

                      <TableCell>{row.ScoreInter}</TableCell>

                      <TableCell>{row.descScoreInter}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableWrapper>
          </CardContent>
        </Card>
      </Grid>
      {localIndicators && (
        <Grid item md={12} sm={12} xs={12}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Box marginBottom={4}>
                <Typography fontSize="1rem">ESG Risk</Typography>
              </Box>
              <Grid container spacing={6}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography
                    color={esgRisk().color}
                    variant="h2"
                    textAlign="center"
                  >
                    {report.localIndicators[0]?.value.description}
                  </Typography>
                  <Box display="flex" justifyContent="center">
                    {[1, 2, 3, 4, 5, 6, 7].map((num) =>
                      num > esgRisk().limit ? (
                        <Box
                          sx={{ height: "20px", width: "70px", margin: "5px" }}
                          bgcolor="#cbcbcb"
                          key={num}
                        />
                      ) : (
                        <Box
                          sx={{ height: "20px", width: "70px", margin: "5px" }}
                          bgcolor={esgRisk().color}
                          key={num}
                        />
                      )
                    )}
                  </Box>
                  <Grid container mt="16px">
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Box display="flex" flexDirection="column" gap={4}>
                        <Typography textAlign="left" fontWeight="bold">
                          {report.localIndicators[0]?.categories[0].description}
                          :
                        </Typography>
                        <Typography textAlign="left" fontWeight="bold">
                          {report.localIndicators[0]?.categories[1].description}
                          :
                        </Typography>
                        <Typography textAlign="left" fontWeight="bold">
                          {report.localIndicators[0]?.categories[2].description}
                          :
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Box
                        display="flex"
                        flexDirection="column"
                        gap={4}
                        justifyContent="flex-end"
                        height="100%"
                      >
                        <Typography textAlign="left">
                          {
                            report.localIndicators[0]?.categories[0].value
                              .description
                          }
                        </Typography>
                        <Typography textAlign="left">
                          {
                            report.localIndicators[0]?.categories[1].value
                              .description
                          }
                        </Typography>
                        <Typography textAlign="left">
                          {
                            report.localIndicators[0]?.categories[2].value
                              .description
                          }
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography>
                    Con ESG Risk ci si riferisce al rischio finanziario
                    associato a fattori ambientali, sociali e di governance.
                    Questi fattori possono influenzare le prestazioni e il
                    valore a lungo termine di un investimento. Ad esempio,
                    un'azienda che non gestisce adeguatamente le questioni
                    ambientali potrebbe essere soggetta a sanzioni normative o
                    affrontare costi operativi più elevati in seguito a problemi
                    ambientali.
                  </Typography>
                  <br />
                  <br />
                  <Typography fontWeight="bold">
                    La valutazione statistica dell'ESG Risk si basa su una ricca
                    gamma di KPI, accuratamente selezionati e calcolati
                    attraverso l'analisi di open-data e informazioni di alta
                    qualità acquisite tramite licenze esclusive.
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};

export default Score;
