import { FormControl, TextField, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Divider, Grid, Typography } from "../style";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

export const parseDate = (dateString) => {
  if (!dateString) return null;
  const [day, month, year] = dateString.split("/");
  return new Date(year, month - 1, day);
};

export const formatDate = (date) => {
  if (!date) return "";
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const DynamicForm = ({ dataset, section, sectName, name, isAccordion }) => {
  const theme = localStorage.getItem("theme");

  // Separate form states: one for main dataset, one for section dataset
  const [formDataset, setFormDataset] = useState({ values: {}, errors: {} });
  const [formSection, setFormSection] = useState({ values: {}, errors: {} });

  useEffect(() => {
    // Initialize main dataset values
    const initialDatasetValues = dataset.reduce((acc, field) => {
      return {
        ...acc,
        [field.label]: field.value || "",
      };
    }, {});

    // Initialize section dataset values
    const initialSectionValues = section?.dataset?.reduce((acc, field) => {
      return {
        ...acc,
        [field.label]: field.value || "",
      };
    }, {});

    setFormDataset({ values: initialDatasetValues, errors: {} });
    setFormSection({ values: initialSectionValues || {}, errors: {} });
  }, [dataset, section]);

  // Validation function for a single field
  const validateField = (field, value) => {
    let error = "";
    if (field.regex && value && !new RegExp(field.regex).test(value)) {
      error = field.errRegex || "Formato non corretto";
    } else if (field.required && !value) {
      error = `${field.label} è un campo obbligatorio`;
    }
    return error;
  };

  // Handle change for main dataset fields
  const handleDatasetChange = (fieldName, value) => {
    const field = dataset.find((el) => el.label === fieldName);
    if (!field) return;

    const error = validateField(field, value);

    setFormDataset((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        [fieldName]: value,
      },
      errors: {
        ...prevState.errors,
        [fieldName]: error || undefined,
      },
    }));
  };

  // Handle change for section dataset fields
  const handleSectionChange = (fieldName, value) => {
    const field = section?.dataset?.find((el) => el.label === fieldName);
    if (!field) return;

    const error = validateField(field, value);

    setFormSection((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        [fieldName]: value,
      },
      errors: {
        ...prevState.errors,
        [fieldName]: error || undefined,
      },
    }));
  };

  // Single date change handler that decides which state to update
  const handleDatePickerChange = (newValue, fieldName, isSection = false) => {
    if (newValue && dayjs(newValue).isValid()) {
      const formattedDate = dayjs(newValue).format("DD/MM/YYYY");
      isSection
        ? handleSectionChange(fieldName, formattedDate)
        : handleDatasetChange(fieldName, formattedDate);
    } else {
      isSection
        ? handleSectionChange(fieldName, "")
        : handleDatasetChange(fieldName, "");
    }
  };

  // Renders an input field for either dataset or section
  const renderInputField = (field, isSection = false) => {
    const { values, errors } = isSection ? formSection : formDataset;
    const value = values[field.label] ?? "";
    const error = errors[field.label];
    const hasError = !!error;

    if (field.type === "date") {
      return (
        <DatePicker
          label={`${field.label} ${field.required ? "*" : ""}`}
          value={value ? dayjs(parseDate(value)) : null}
          onChange={(newValue) =>
            handleDatePickerChange(newValue, field.label, isSection)
          }
          renderInput={(params) => (
            <TextField
              {...params}
              disabled={!field.editable}
              fullWidth
              error={hasError}
              helperText={hasError ? error : ""}
            />
          )}
          disableFuture
          openTo="year"
          views={["year", "month", "day"]}
        />
      );
    }

    return (
      <TextField
        disabled={!field.editable}
        label={`${field.label} ${field.required ? "*" : ""}`}
        name={field.label}
        value={value}
        onChange={(event) =>
          isSection
            ? handleSectionChange(field.label, event.target.value)
            : handleDatasetChange(field.label, event.target.value)
        }
        fullWidth
        sx={
          theme === '"LIGHT"' && {
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "black",
            },
            "& .MuiInputLabel-root.Mui-disabled": {
              color: "rgba(0, 0, 0, 0.6)",
            },
          }
        }
        error={hasError}
        helperText={hasError ? error : ""}
      />
    );
  };

  // Validate the entire form (both dataset and section)
  const validateForm = () => {
    // Validate main dataset fields
    const datasetErrors = {};
    dataset.forEach((field) => {
      const error = validateField(field, formDataset.values[field.label]);
      if (error) {
        datasetErrors[field.label] = error;
      }
    });

    // Validate section fields
    const sectionErrors = {};
    section?.dataset?.forEach((field) => {
      const error = validateField(field, formSection.values[field.label]);
      if (error) {
        sectionErrors[field.label] = error;
      }
    });

    // Update both states with new errors
    setFormDataset((prevState) => ({ ...prevState, errors: datasetErrors }));
    setFormSection((prevState) => ({ ...prevState, errors: sectionErrors }));

    return (
      Object.keys(datasetErrors).length === 0 &&
      Object.keys(sectionErrors).length === 0
    );
  };

  const handleSubmit = () => {
    if (validateForm()) {
      const { values: datasetValues } = formDataset;
      const { values: sectionValues } = formSection;

      // Start the Cliente object with all the main dataset values
      const Cliente = {
        ...datasetValues,
      };

      // Add "legale" only if section is defined and sectionValues is not empty
      if (section && Object.keys(sectionValues).length > 0) {
        Cliente.legale = { ...sectionValues };
      }

      console.log("Form submitted successfully");
      console.log("Cliente object:", Cliente);
    } else {
      console.log("Form contains errors", {
        datasetErrors: formDataset.errors,
        sectionErrors: formSection.errors,
      });
    }
  };

  // Disable button logic remains the same
  const isBtnDisabled =
    !dataset.some((el) => el.editable) &&
    !section?.dataset?.some((el) => el.editable);

  return (
    <Grid container spacing={3}>
      {!isAccordion && (
        <Grid item xs={12}>
          <Typography my={6} variant="h6">
            {name}
          </Typography>
        </Grid>
      )}

      {/* Main dataset fields */}
      <Grid container item spacing={3}>
        {dataset.map((field) => (
          <Grid item xs={12} sm={4} key={field.label}>
            <FormControl fullWidth>{renderInputField(field)}</FormControl>
          </Grid>
        ))}
      </Grid>

      {/* Section fields */}
      {section && (
        <Grid item xs={12}>
          <Box sx={{ translate: "12px 0" }}>
            <Divider my={6} />
            <Typography my={6}>{sectName}</Typography>
            <Grid container spacing={3}>
              {section.dataset.map((field) => (
                <Grid item xs={12} sm={4} key={field.label}>
                  <FormControl fullWidth>
                    {renderInputField(field, true)}
                  </FormControl>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      )}

      <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          variant="outlined"
          mt={5}
          ml={3}
          disabled={isBtnDisabled}
          onClick={handleSubmit}
        >
          Salva
        </Button>
      </Grid>
    </Grid>
  );
};

export default DynamicForm;
