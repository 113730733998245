import React, { useState } from "react";
import styled from "@emotion/styled";

import {
  Box,
  Button,
  Card as MuiCard,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import {
  ChipBlue,
  ChipGreen,
  ChipGrey,
  ChipOrange,
  ChipRed,
  ChipYellow,
} from "./style";

import Modale from "./modale/Modale";
import { ArrowDropDown, ArrowRight } from "@mui/icons-material";

const Card = styled(MuiCard)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const Tabella = ({ table }) => {
  const [subTable, setSubTable] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [servizioModal, setServizioModal] = useState("");
  const [botModal, setBotModal] = useState("");
  const [statoModal, setStatoModal] = useState("");
  const [scope, setScope] = useState("");
  const [codiceScope, setCodiceScope] = useState();
  const [codiceServizio, setCodiceServizio] = useState();
  const [idUidBot, setIdUidBot] = useState();
  const [statoCodice, setStatoCodice] = useState();

  const handleClick = (nome) => {
    if (subTable === nome) {
      setSubTable("");
    } else {
      setSubTable(nome);
    }
  };

  const handleModal = (
    servizio,
    bot,
    codiceServizio,
    idUidBot,
    scope,
    codiceScope
  ) => {
    setIsModalOpen(true);
    setServizioModal(servizio);
    setBotModal(bot);
    setStatoModal("Errore");
    setCodiceServizio(codiceServizio);
    setIdUidBot(idUidBot);
    setStatoCodice(4);
    setScope(scope);
    setCodiceScope(codiceScope);
    console.log(scope);
  };

  const formula = (errore, ok, ng) => {
    const addizione = errore + ok + ng;
    const divisioneMoltiplicazione = (errore / addizione) * 100;
    const risultato = 100 - divisioneMoltiplicazione;
    if (isNaN(risultato)) {
      return;
    }
    if (risultato === 100 && risultato.toString().indexOf(".") === -1) {
      return <ChipGreen label={risultato.toString() + "%"} />;
    } else if (risultato >= 95) {
      return <ChipGreen label={risultato.toFixed(2) + "%"} />;
    } else if (risultato < 90) {
      return <ChipRed label={risultato.toFixed(2) + "%"} />;
    } else {
      return <ChipOrange label={risultato.toFixed(2) + "%"} />;
    }
  };

  const SwitchOptions = {
    OPTION1: "Servizio",
    OPTION2: "Bot",
  };

  const [activeOption, setActiveOption] = useState(SwitchOptions.OPTION1);

  const handleSwitchClick = (option) => {
    setActiveOption(option);
  };

  return (
    <Card mb={6}>
      <Box
        m={4}
        display="flex"
        justifyContent="end"
        alignItems="center"
        gap={8}
      >
        <Typography fontWeight="bold">Ordina per</Typography>
        <Box
          display="flex"
          alignItems="center"
          width="max-content"
          height="max-content"
          borderRadius={35}
          color="white"
          bgcolor="#0b5485"
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            p="8px 12px"
            height="40px"
            width="80px"
            borderRadius={35}
            bgcolor={
              activeOption === SwitchOptions.OPTION1 ? "#0f74b9" : "transparent"
            }
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSwitchClick(SwitchOptions.OPTION1)}
          >
            <div className={"Text"}>Servizio</div>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            p="8px 12px"
            height="40px"
            width="80px"
            borderRadius={35}
            bgcolor={
              activeOption === SwitchOptions.OPTION2 ? "#0f74b9" : "transparent"
            }
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSwitchClick(SwitchOptions.OPTION2)}
          >
            <div className={"Text"}>Bot</div>
          </Box>
        </Box>
      </Box>
      <Paper>
        <TableWrapper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  {activeOption === SwitchOptions.OPTION1 ? "Servizio" : "Bot"}
                </TableCell>
                <TableCell align="center">Stato</TableCell>
                <TableCell align="center">%Successo</TableCell>
                <TableCell align="center">Pending</TableCell>
                <TableCell align="center">OK</TableCell>
                <TableCell align="center">Errore</TableCell>
                <TableCell align="center">Non Gestibile</TableCell>
              </TableRow>
            </TableHead>
            {activeOption === SwitchOptions.OPTION1 ? (
              <TableBody>
                {table.map((row) => (
                  <React.Fragment key={row.Codice}>
                    <TableRow>
                      <TableCell align="left">
                        <Box display="flex" alignItems="center">
                          <Box
                            onClick={() => handleClick(row.Nome)}
                            sx={{
                              cursor: "pointer",
                            }}
                          >
                            {subTable === row.Nome ? (
                              <ArrowDropDown />
                            ) : (
                              <ArrowRight />
                            )}
                          </Box>
                          <Typography
                            fontWeight="bold"
                            fontSize="16px"
                            color="primary"
                            sx={{
                              cursor: "pointer",
                              ":hover": {
                                textDecoration: "underline",
                              },
                            }}
                            onClick={() =>
                              handleModal(
                                row.Nome,
                                "",
                                row.Codice,
                                "",
                                row.ScopeDesc,
                                row.Scope
                              )
                            }
                          >
                            {row.Nome}
                          </Typography>
                        </Box>
                        <Typography fontSize="10px">{row.Codice}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Stack
                          direction="row"
                          gap="4px"
                          display="flex"
                          justifyContent="center"
                        >
                          {row.statoAttesa !== 0 && (
                            <Tooltip title="Attesa">
                              <ChipGreen label={`${row.statoAttesa}`} />
                            </Tooltip>
                          )}
                          {row.statoSospeso !== 0 && (
                            <Tooltip title="Sospeso">
                              <ChipRed label={`${row.statoSospeso}`} />
                            </Tooltip>
                          )}

                          {row.statoErrore !== 0 && (
                            <Tooltip title="Errore">
                              <ChipRed label={`${row.statoErrore}`} />
                            </Tooltip>
                          )}
                          {row.statoLavorazione !== 0 && (
                            <Tooltip title="Lavorazione">
                              <ChipGreen label={`${row.statoLavorazione}`} />
                            </Tooltip>
                          )}

                          {row.statoInattivo !== 0 && (
                            <Tooltip title="Inattivo">
                              <ChipGrey label={`${row.statoInattivo}`} />
                            </Tooltip>
                          )}
                        </Stack>
                      </TableCell>
                      <TableCell align="center">
                        {formula(row.totErrore, row.totOK, row.totNonGestibili)}
                      </TableCell>
                      <TableCell align="center">
                        {row.totLavorazione !== 0 && (
                          <ChipYellow label={row.totLavorazione} />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {row.totOK !== 0 && <ChipGreen label={row.totOK} />}
                      </TableCell>
                      <TableCell align="center">
                        {row.totErrore !== 0 && (
                          <ChipRed label={row.totErrore} />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {row.totNonGestibili !== 0 && (
                          <ChipOrange label={row.totNonGestibili} />
                        )}
                      </TableCell>
                    </TableRow>
                    {subTable === row.Nome &&
                      row.dataset.map((subRow) => (
                        <TableRow key={subRow.ID}>
                          <TableCell align="right" sx={{ paddingY: "4px" }}>
                            <Typography
                              fontSize="14px"
                              fontWeight="bold"
                              color="primary"
                              sx={{
                                display: "inline-block",
                                cursor: "pointer",
                                ":hover": {
                                  textDecoration: "underline",
                                },
                              }}
                              onClick={() =>
                                handleModal(
                                  row.Nome,
                                  subRow.nome,
                                  row.Codice,
                                  subRow.idUid,
                                  row.ScopeDesc,
                                  row.Scope
                                )
                              }
                            >
                              {subRow.nome}
                            </Typography>
                            <Typography sx={{ fontSize: 10 }}>
                              {subRow.RemoteHost}
                            </Typography>
                            <Typography sx={{ fontSize: 10 }}>
                              {subRow.LastUpdate}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {subRow.Stato === 6 && (
                              <ChipGreen
                                label="Attesa"
                                sx={{ width: "105px" }}
                              />
                            )}
                            {subRow.Stato === 5 && (
                              <ChipRed
                                label="Sospeso"
                                sx={{ width: "105px" }}
                              />
                            )}
                            {subRow.Stato === 4 && (
                              <ChipRed label="Errore" sx={{ width: "105px" }} />
                            )}
                            {subRow.Stato === 2 && (
                              <ChipGreen
                                label="Lavorazione"
                                sx={{ width: "105px" }}
                              />
                            )}
                            {subRow.Stato === 0 && (
                              <ChipGrey
                                label="Inattivo"
                                sx={{ width: "105px" }}
                              />
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {subRow.Stato !== 0 &&
                              ((subRow.Stato === 5 && subRow.totOK !== 0) ||
                                subRow.totErrore !== 0 ||
                                subRow.totNonGestibili !== 0) &&
                              formula(
                                subRow.totErrore,
                                subRow.totOK,
                                subRow.totNonGestibili
                              )}
                          </TableCell>
                          <TableCell align="center">
                            {subRow.totLavorazione !== 0 && (
                              <ChipYellow label={subRow.totLavorazione} />
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {subRow.totOK !== 0 && (
                              <ChipGreen label={subRow.totOK} />
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {subRow.totErrore !== 0 && (
                              <ChipRed label={subRow.totErrore} />
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {subRow.totNonGestibili !== 0 && (
                              <ChipOrange label={subRow.totNonGestibili} />
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </React.Fragment>
                ))}
              </TableBody>
            ) : (
              <TableBody>
                {table.map((row) => (
                  <React.Fragment key={row.Codice}>
                    {row.dataset.map((subRow) => (
                      <TableRow key={subRow.ID}>
                        <TableCell align="left" sx={{ paddingY: "4px" }}>
                          <Typography
                            fontSize="14px"
                            fontWeight="bold"
                            color="primary"
                            sx={{
                              display: "inline-block",
                              cursor: "pointer",
                              ":hover": {
                                textDecoration: "underline",
                              },
                            }}
                            onClick={() =>
                              handleModal(
                                row.Nome,
                                subRow.nome,
                                row.Codice,
                                subRow.idUid,
                                row.ScopeDesc,
                                row.Scope
                              )
                            }
                          >
                            {subRow.nome}
                          </Typography>
                          <Typography sx={{ fontSize: 10 }}>
                            {subRow.RemoteHost}
                          </Typography>
                          <Typography sx={{ fontSize: 10 }}>
                            {subRow.LastUpdate}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {subRow.Stato === 6 && (
                            <ChipGreen label="Attesa" sx={{ width: "105px" }} />
                          )}
                          {subRow.Stato === 5 && (
                            <ChipRed label="Sospeso" sx={{ width: "105px" }} />
                          )}
                          {subRow.Stato === 4 && (
                            <ChipRed label="Errore" sx={{ width: "105px" }} />
                          )}
                          {subRow.Stato === 2 && (
                            <ChipGreen
                              label="Lavorazione"
                              sx={{ width: "105px" }}
                            />
                          )}
                          {subRow.Stato === 0 && (
                            <ChipGrey
                              label="Inattivo"
                              sx={{ width: "105px" }}
                            />
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {subRow.Stato !== 0 &&
                            ((subRow.Stato === 5 && subRow.totOK !== 0) ||
                              subRow.totErrore !== 0 ||
                              subRow.totNonGestibili !== 0) &&
                            formula(
                              subRow.totErrore,
                              subRow.totOK,
                              subRow.totNonGestibili
                            )}
                        </TableCell>
                        <TableCell align="center">
                          {subRow.totLavorazione !== 0 && (
                            <ChipYellow label={subRow.totLavorazione} />
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {subRow.totOK !== 0 && (
                            <ChipGreen label={subRow.totOK} />
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {subRow.totErrore !== 0 && (
                            <ChipRed label={subRow.totErrore} />
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {subRow.totNonGestibili !== 0 && (
                            <ChipOrange label={subRow.totNonGestibili} />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </React.Fragment>
                ))}
              </TableBody>
            )}
          </Table>
        </TableWrapper>
        <Modale
          open={isModalOpen}
          setOpen={setIsModalOpen}
          dati={table}
          servizioProp={servizioModal}
          botProp={botModal}
          statoProp={statoModal}
          codiceServizioProp={codiceServizio}
          idUidBotProp={idUidBot}
          codiceStatoProp={statoCodice}
          openFrom="TabellaPrincipale"
          scopeProp={scope}
          codiceScopeProp={codiceScope}
        />
      </Paper>
    </Card>
  );
};

export default Tabella;
