import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/system";
import React, { useState } from "react";
import { showSnackbar } from "../../../redux/slices/snackbar";
import { useDispatch } from "react-redux";
import { Search } from "@mui/icons-material";
import { ChipGreen, ChipRed } from "../services/style";
/* import { columnsDatagrid } from "./columnsDatagrid"; */

const Modale = ({ open, setOpen }) => {
  const isUnder600px = useMediaQuery("(max-width:600px)");
  const theme = useTheme();
  const [rows, setRows] = useState([]);
  const [ricerca, setRicerca] = useState("");
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
    setRicerca("");
    setError(false);
    setRows([]);
  };

  const handleRicerca = () => {
    const botNameRegex = /^(['0-9a-zA-ZÀ-ÿ ]{2,50}|[0-9]{11})$/;
    if (botNameRegex.test(ricerca)) {
      Ricerca();
    } else {
      setError(true);
    }
  };

  const handleChange = (e) => {
    setError(false);
    setRicerca(e.target.value);
  };

  const handleClick = () => {}; // FINIRE QUI

  const handleSnackbar = (message, severity) => {
    dispatch(showSnackbar({ message: message, severity: severity }));
  };

  async function Ricerca() {
    const token = sessionStorage.getItem("token");

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token,
          cercare: ricerca,
        }),
      };

      const response = await fetch(
        "https://devops.mobilityexpress.it/api/csSearch",
        requestOptions
      );

      const responseData = await response.json();
      //Ragione sociale = name (link dove passa il campo id nell'url della nuova pagina) , N. REA = regNo, Stato = status (chip), Indirizzo = simpleValue, P.IVA = vatNo
      if (responseData.status === 1) {
        handleSnackbar(responseData.message, "success");
        setRows(responseData.data.companies);
      } else {
        handleSnackbar(responseData.message, "error");
      }
    } catch (error) {
      return error;
    }
  }

  return (
    <Dialog
      open={open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "900px", // Set your width here
            backgroundColor:
              theme.palette.mode === "dark" ? "#1B2635" : "#F7F9FC",
          },
        },
      }}
    >
      <DialogTitle>Ricerca Società</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            my: 2,
            mb: 4,
          }}
          style={
            isUnder600px
              ? { display: "flex", flexDirection: "column", gap: 8 }
              : { display: "flex", alignItems: "center", gap: 8 }
          }
        >
          <FormControl style={{ minWidth: 250 }}>
            <TextField
              size="small"
              margin="none"
              label="Ragione Sociale o Partita IVA"
              sx={{ width: "100%" }}
              onChange={handleChange}
              inputProps={{
                pattern: "^(['0-9a-zA-ZÀ-ÿ ]{2,50}|[0-9]{11})$",
              }}
              error={error}
            />
          </FormControl>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              onClick={handleRicerca}
              sx={{ flex: 1 }}
            >
              Ricerca
            </Button>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ width: "100%" }} my={4}>
          <Card>
            <CardContent
              sx={{
                p: 0,
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: isUnder600px ? "100%" : "300px",
                }}
              >
                <Typography fontWeight="bold" fontSize="16px" sx={{ mb: 4 }}>
                  Risultati: {rows.length}
                </Typography>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow
                        sx={{
                          bgcolor:
                            theme.palette.mode === "dark" ? "#233044" : "#FFF",
                        }}
                      >
                        <TableCell align="left">Ragione Sociale</TableCell>
                        <TableCell align="center">P. IVA</TableCell>
                        <TableCell align="center">N. REA</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{
                        bgcolor:
                          theme.palette.mode === "dark" ? "#233044" : "#FFF",
                      }}
                    >
                      {rows.map((row) => (
                        <React.Fragment key={row.id}>
                          <TableRow>
                            <TableCell align="left">
                              <Box
                                sx={{ width: isUnder600px ? "200px" : "100%" }}
                              >
                                <Typography
                                  fontWeight="bold"
                                  color="primary"
                                  sx={{
                                    cursor: "pointer",
                                    ":hover": {
                                      textDecoration: "underline",
                                    },
                                  }}
                                  onClick={handleClick}
                                >
                                  {row.name}
                                </Typography>
                                <Typography fontSize="10px">
                                  {row.address.simpleValue}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell align="center">
                              <Typography sx={{ mb: 2 }}>
                                {row.vatNo[0]}
                              </Typography>
                              {row.status === "Active" ? (
                                <ChipGreen label="Attivo" />
                              ) : (
                                <ChipRed label="Non Attivo" />
                              )}
                            </TableCell>
                            <TableCell align="center">{row.regNo}</TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </CardContent>
          </Card>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          sx={{ marginRight: "8px" }}
        >
          Chiudi
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Modale;
