import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Close";
import { GridRowModes, DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import {
  FormControl,
  IconButton,
  Paper,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import PopoverDelete from "./PopoverDelete";
import { useEffect } from "react";
import { useTheme } from "@emotion/react";
import { NumericFormat } from "react-number-format";
import { render } from "@testing-library/react";

export default function Tabella({ data, tipoVeicolo, setDatagrid }) {
  const [rows, setRows] = useState(data.Tariffe[0].dataset);
  const [rowModesModel, setRowModesModel] = useState({});

  const tipoConto = data.Tariffe[0].tipoConto;
  const classeArray = data.Tariffe[0].classe[0].value;

  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [currentDeleteRef, setCurrentDeleteRef] = useState(null);
  const deleteRefs = React.useRef({});

  const handleCloseDeletePopup = () => {
    setIsDeletePopupOpen(false);
  };

  useEffect(() => {
    setRows(rows);
    setDatagrid(rows);
  }, [rows]);

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    const savedRow = rows.find((row) => row.id === id);
    if (savedRow.newRow) {
      delete savedRow.newRow;
    }
    console.log(savedRow);
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.newRow) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    if (newRow.isNew === 0) {
      const updatedRow = { ...newRow };
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      return updatedRow;
    } else {
      const updatedRow = { ...newRow, isNew: 1 };
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      return updatedRow;
    }
  };

  const columns = [
    {
      field: "id",
    },
    {
      field: "idTipoConto",
      headerName: "Tipo Conto",
      width: 150,
      headerAlign: "center",
      align: "center",
      editable: true,
      type: "singleSelect",
      valueOptions: tipoConto,
      valueFormatter: ({ value, field, api }) => {
        const colDef = api.getColumn(field);
        const option = colDef.valueOptions.find(
          ({ value: optionValue }) => value === optionValue
        );
        if (option) return option.label;
      },
    },
    {
      field: "cvDa",
      headerName: tipoVeicolo === 3 ? "Quintali Da" : "Cavalli Da",
      width: 150,
      headerAlign: "center",
      align: "center",
      editable: true,
      renderEditCell: (params) => (
        <NumericFormat
          customInput={TextField} // Ensures proper input behavior in edit mode
          value={params.value}
          onValueChange={(values) => {
            const { value } = values;
            params.api.setEditCellValue({
              id: params.id,
              field: params.field,
              value: value === "" ? "0" : value,
            });
          }}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
        />
      ),
    },
    {
      field: "cvA",
      headerName: tipoVeicolo === 3 ? "Quintali A" : "Cavalli A",
      width: 150,
      headerAlign: "center",
      align: "center",
      editable: true,
      renderEditCell: (params) => (
        <NumericFormat
          customInput={TextField} // Ensures proper input behavior in edit mode
          value={params.value}
          onValueChange={(values) => {
            const { value } = values;
            params.api.setEditCellValue({
              id: params.id,
              field: params.field,
              value: value === "" ? "0" : value,
            });
          }}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
        />
      ),
    },
    {
      field: "classeDa",
      headerName: "Classe Da",
      width: 150,
      headerAlign: "center",
      align: "center",
      editable: true,
      type: "singleSelect",
      valueOptions: classeArray,
    },
    {
      field: "classeA",
      headerName: "Classe A",
      width: 150,
      headerAlign: "center",
      align: "center",
      editable: true,
      type: "singleSelect",
      valueOptions: classeArray,
    },
    {
      field: "tariffaAcquisto",
      headerName: "Prezzo Acquisto",
      width: 150,
      headerAlign: "center",
      align: "center",
      editable: true,
      renderCell: (params) => (
        <NumericFormat
          value={params.value}
          valueIsNumericString
          prefix="€"
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          displayType="text"
        />
      ),
      renderEditCell: (params) => (
        <NumericFormat
          customInput={TextField} // Ensures proper input behavior in edit mode
          value={params.value}
          onValueChange={(values) => {
            const { value } = values;
            params.api.setEditCellValue({
              id: params.id,
              field: params.field,
              value: value === "" ? "0" : value,
            });
          }}
          prefix="€"
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
        />
      ),
    },
    {
      field: "tariffaVendita",
      headerName: "Prezzo Vendita",
      width: 150,
      headerAlign: "center",
      align: "center",
      editable: true,
      renderCell: (params) => (
        <NumericFormat
          value={params.value}
          valueIsNumericString
          prefix="€"
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          displayType="text"
        />
      ),
      renderEditCell: (params) => (
        <NumericFormat
          customInput={TextField} // Ensures proper input behavior in edit mode
          value={params.value || 0}
          onValueChange={(values) => {
            const { value } = values;
            params.api.setEditCellValue({
              id: params.id,
              field: params.field,
              value: value === "" ? "0" : value,
            });
          }}
          prefix="€"
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
        />
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const ref = (el) => {
          deleteRefs.current[id] = el;
        };
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<CheckIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,

          <>
            <PopoverDelete
              isDeletePopupOpen={
                isDeletePopupOpen && currentDeleteRef === deleteRefs.current[id]
              }
              handleCloseDeletePopup={handleCloseDeletePopup}
              rebootRef={deleteRefs.current[id]}
              handleDelete={handleDeleteClick(id)}
            />
            <IconButton
              ref={ref}
              sx={{ p: 0, marginRight: "8px" }}
              onClick={() => {
                setIsDeletePopupOpen(true); // Open the popover
                setCurrentDeleteRef(deleteRefs.current[id]);
              }}
            >
              <DeleteIcon color="secondary" />
            </IconButton>
          </>,
        ];
      },
    },
  ];
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const handleClick = () => {
    let id;
    const prefix = "99999";
    do {
      id = prefix + Math.floor(Math.random() * 1000);
    } while (rows.some((row) => row.id === id));

    setRows((oldRows) => [
      ...oldRows,
      {
        id,
        idTipoConto: tipoVeicolo === 3 ? 3 : 2,
        cvDa: "0",
        cvA: "0",
        classeDa: 1,
        classeA: 1,
        tariffaAcquisto: "0",
        tariffaVendita: "0",
        isNew: 1,
        newRow: true,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "id" },
    }));
  };

  return (
    <Box>
      {/* <PopoverDelete
        open={dialogOpen}
        handleClose={handleCancelDelete}
        handleDelete={handleConfirmDelete}
      /> */}
      <Box sx={{ width: "100%", height: 500, position: "relative" }}>
        <Button
          variant="contained"
          onClick={handleClick}
          sx={{
            position: "absolute",
            left: isDesktop ? "32px" : "16px",
            bottom: "19px",
            zIndex: "10",
          }}
        >
          <AddIcon />
        </Button>
        <Paper sx={{ height: 500, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            getRowId={(row) => row.id}
            experimentalFeatures={{ newEditingApi: true }}
            rowsPerPageOptions={[100]}
            localeText={{ noRowsLabel: "Non ci sono risultati" }}
            disableSelectionOnClick
            allowUpdating={true}
            sx={{
              "& .MuiDataGrid-row--selected": {
                backgroundColor: "rgba(0, 0, 255, 0.1)",
              },
              "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-footerContainer": {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px",
              },
            }}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  // Hide columns status and traderName, the other columns will remain visible
                  id: false,
                },
              },
            }}
          />
        </Paper>
      </Box>
    </Box>
  );
}
