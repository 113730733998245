import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";

const Opzioni = ({ data, setOpzioni }) => {
  const [isChecked, setIsChecked] = useState({
    contraente: data.contraente === 1 ? 1 : 0,
    franchigia: data.franchigia === 1 ? 1 : 0,
    ratinoRinnovo: data.ratinoRinnovo === 1 ? 1 : 0,
    rinunciaRivalsa: data.rinunciaRivalsa === 1 ? 1 : 0,
    autorizzazioneIncasso: data.autorizzazioneIncasso === 1 ? 1 : 0,
  });

  const numberCosto = data.costoIngresso || "0";

  const [costoIngresso, setCostoIngresso] = useState(numberCosto);

  const [scMag, setScMag] = useState(data.scontoMaggiorazione[0].value || "");
  const [tipoScMag, setTipoScMag] = useState(
    data.scontoMaggiorazioneTipo[0].value || ""
  );
  const scMagArray = data.scontoMaggiorazione[0];
  const tipoScMagArray = data.scontoMaggiorazioneTipo[0];

  const [valoreScMag, setValoreScMag] = useState(
    data.scontoMaggiorazioneValore
  );
  const [contraenteNota, setContraenteNota] = useState(data.contraenteNota);
  const [franchigiaImporto, setFranchigiaImporto] = useState(
    data.franchigiaImporto
  );
  const [franchigiaValore, setFranchigiaValore] = useState(
    data.franchigiaValore
  );

  const [franchigiaScoperto, setFranchigiaScoperto] = useState(
    data.franchigiaScoperto[0].value
  );
  const franchigiaScopertoArray = data.franchigiaScoperto[0];
  const [franchigiaNote, setFranchigiaNote] = useState(data.franchigiaNota);
  const [ratinoRinnovoGiorni, setRatinoRinnovoGiorni] = useState(
    data.ratinoRinnovoGiorni
  );

  const [caricoScarico, setCaricoScarico] = useState(
    data.caricoScarico[0].value
  );
  const caricoScaricoArray = data.caricoScarico[0];

  const [tipoGuida, setTipoGuida] = useState(data.tipoGuida[0].value);
  const tipoGuidaArray = data.tipoGuida[0];

  const [massimale, setMassimale] = useState(data.massimale);

  const {
    contraente,
    franchigia,
    ratinoRinnovo,
    rinunciaRivalsa,
    autorizzazioneIncasso,
  } = isChecked;

  useEffect(() => {
    setOpzioni({
      scontoMaggiorazione: scMag,
      scontoMaggiorazioneTipo: tipoScMag,
      scontoMaggiorazioneValore: valoreScMag,
      franchigia: franchigia,
      franchigiaImporto: franchigiaImporto,
      franchigiaValore: franchigiaValore,
      franchigiaScoperto: franchigiaScoperto,
      franchigiaNota: franchigiaNote,
      contraente: contraente,
      contraenteNota: contraenteNota,
      ratinoRinnovo: ratinoRinnovo,
      ratinoRinnovoGiorni: ratinoRinnovoGiorni,
      tipoGuida: tipoGuida,
      caricoScarico: caricoScarico,
      costoIngresso: costoIngresso,
      massimale: massimale,
      rinunciaRivalsa: rinunciaRivalsa,
      autorizzazioneIncasso: autorizzazioneIncasso,
    });
  }, [
    scMag,
    tipoScMag,
    valoreScMag,
    franchigia,
    franchigiaImporto,
    franchigiaValore,
    franchigiaScoperto,
    franchigiaNote,
    contraente,
    contraenteNota,
    ratinoRinnovo,
    ratinoRinnovoGiorni,
    tipoGuida,
    caricoScarico,
    costoIngresso,
    massimale,
    rinunciaRivalsa,
    autorizzazioneIncasso,
  ]);

  const handleChange = (event) => {
    setIsChecked({
      ...isChecked,
      [event.target.name]: event.target.checked ? 1 : 0,
    });
  };

  return (
    <Box>
      <Box display="flex" flexDirection="column" gap={4} paddingTop="8px">
        <Typography variant="h4">Sconto Maggiorazione</Typography>
        <Box>
          <Grid container spacing={4}>
            <Grid item sm={3} xs={12}>
              <TextField
                id="scMag"
                fullWidth
                select
                label="Sc/Mag"
                defaultValue={scMagArray.value}
                value={scMag}
              >
                {scMagArray.dataset.map((el) => (
                  <MenuItem
                    key={el.label}
                    value={el.value}
                    onClick={() => setScMag(el.value)}
                  >
                    {el.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item sm={3} xs={12}>
              <TextField
                id="tipoScMag"
                fullWidth
                select
                label="Tipo Sc/Mag"
                defaultValue={tipoScMagArray.value}
                value={tipoScMag}
              >
                {tipoScMagArray.dataset.map((el) => (
                  <MenuItem
                    key={el.label}
                    value={el.value}
                    onClick={() => setTipoScMag(el.value)}
                  >
                    {el.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item sm={3} xs={12}>
              <FormControl fullWidth size="small" margin="none">
                <NumericFormat
                  value={valoreScMag}
                  onValueChange={(values) => setValoreScMag(values.value)}
                  customInput={TextField}
                  valueIsNumericString
                  prefix="€"
                  label="Valore Sc/Mag"
                  id="valoreScMag"
                  thousandSeparator="."
                  decimalSeparator=","
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Divider my={4} />
        <Box display="flex" flexDirection="column">
          <Box display="flex" alignItems="center" mb="8px">
            <FormControlLabel
              control={
                <Switch
                  checked={franchigia === 1 ? true : false}
                  onChange={handleChange}
                  name="franchigia"
                />
              }
            />
            <Typography variant="h4">Franchigia</Typography>
          </Box>
          <Grid container spacing={4}>
            <Grid item sm={3} xs={12}>
              <FormControl fullWidth size="small" margin="none">
                <NumericFormat
                  disabled={!franchigia ?? true}
                  value={franchigiaImporto}
                  onValueChange={(values) => setFranchigiaImporto(values.value)}
                  customInput={TextField}
                  valueIsNumericString
                  prefix="€"
                  label="Importo"
                  id="importo"
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                />
              </FormControl>
            </Grid>
            <Grid item sm={3} xs={12}>
              <TextField
                disabled={!franchigia ?? true}
                size="small"
                margin="none"
                label="Valore"
                fullWidth
                value={franchigiaValore}
                onChange={(event) => setFranchigiaValore(event.target.value)}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <TextField
                disabled={!franchigia ?? true}
                label="Scoperto"
                size="small"
                margin="none"
                select
                fullWidth
                defaultValue={franchigiaScopertoArray.value}
                value={franchigiaScoperto}
              >
                {franchigiaScopertoArray.dataset.map((el) => (
                  <MenuItem
                    key={el.label}
                    value={el.value}
                    onClick={() => setFranchigiaScoperto(el.value)}
                  >
                    {el.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item sm={3} xs={12}>
              <TextField
                disabled={!franchigia ?? true}
                size="small"
                margin="none"
                label="Note"
                fullWidth
                value={franchigiaNote}
                onChange={(event) => setFranchigiaNote(event.target.value)}
              />
            </Grid>
          </Grid>
        </Box>
        <Divider my={4} />
        <Box display="flex" flexDirection="column">
          <Box display="flex" alignItems="center" mb="8px">
            <FormControlLabel
              control={
                <Switch
                  checked={contraente === 1 ? true : false}
                  onChange={handleChange}
                  name="contraente"
                />
              }
            />
            <Typography variant="h4">Contraente</Typography>
          </Box>

          <Grid container spacing={4}>
            <Grid item sm={3} xs={12}>
              <TextField
                disabled={!contraente ?? true}
                size="small"
                margin="none"
                label="Note"
                fullWidth
                value={contraenteNota}
                onChange={(event) => setContraenteNota(event.target.value)}
              />
            </Grid>
          </Grid>
        </Box>
        <Divider my={4} />
        <Box display="flex" flexDirection="column">
          <Box display="flex" alignItems="center" mb="8px">
            <FormControlLabel
              control={
                <Switch
                  checked={ratinoRinnovo === 1 ? true : false}
                  onChange={handleChange}
                  name="ratinoRinnovo"
                />
              }
            />
            <Typography variant="h4">Ratino + Rinnovo</Typography>
          </Box>
          <Grid container spacing={4}>
            <Grid item sm={3} xs={12}>
              <TextField
                disabled={!ratinoRinnovo ?? true}
                value={ratinoRinnovoGiorni}
                fullWidth
                onChange={(event) => setRatinoRinnovoGiorni(event.target.value)}
                label="Giorni"
              />
            </Grid>
          </Grid>
        </Box>
        <Divider my={4} />
        <Grid container spacing={4}>
          <Grid item sm={3} xs={12}>
            <TextField
              id="tipoGuida"
              fullWidth
              select
              label="Tipo Guida"
              defaultValue={tipoGuidaArray.value}
              value={tipoGuida}
            >
              {tipoGuidaArray.dataset.map((el) => (
                <MenuItem
                  key={el.label}
                  value={el.value}
                  onClick={() => setTipoGuida(el.value)}
                >
                  {el.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item sm={3} xs={12}>
            <TextField
              id="caricoScarico"
              fullWidth
              select
              label="Carico Scarico"
              defaultValue={caricoScaricoArray.value}
              value={caricoScarico}
            >
              {caricoScaricoArray.dataset.map((el) => (
                <MenuItem
                  key={el.label}
                  value={el.value}
                  onClick={() => setCaricoScarico(el.value)}
                >
                  {el.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item sm={3} xs={12}>
            <FormControl fullWidth size="small" margin="none">
              <NumericFormat
                value={costoIngresso}
                onValueChange={(values) => setCostoIngresso(values.value)}
                customInput={TextField}
                valueIsNumericString
                prefix="€"
                label="Costo Ingresso"
                id="costoIngresso"
                thousandSeparator="."
                decimalSeparator=","
              />
            </FormControl>
          </Grid>
          <Grid item sm={3} xs={12}>
            <FormControl fullWidth size="small" margin="none">
              <NumericFormat
                value={massimale}
                onValueChange={(event) => setMassimale(event.value)}
                label="Massimale"
                customInput={TextField}
                valueIsNumericString
                prefix="€"
                id="massimale"
                thousandSeparator="."
                decimalSeparator=","
              />
            </FormControl>
          </Grid>
        </Grid>
        <Box display="flex" flexDirection="column">
          <FormControlLabel
            control={
              <Checkbox
                checked={rinunciaRivalsa === 1 ? true : false}
                onChange={handleChange}
                name="rinunciaRivalsa"
              />
            }
            label="Rinuncia alla rivalsa"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={autorizzazioneIncasso === 1 ? true : false}
                onChange={handleChange}
                name="autorizzazioneIncasso"
              />
            }
            label="con autorizzazione all'incasso"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Opzioni;
